/*@font-face {
	font-family: painting;
	src:url('./TheHistoriaDemo.ttf');
}*/
body, html {
	margin: 0;
	padding: 0;
	background-color: #222831;
	color: #878bc4;
}
.page {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-direction: column;
	align-items: center;
}
button {
	/*width: 500px;*/
	background-color: #999;
	color: #484c84;
	margin-top: 4rem;
	padding: 18px 50px;
	border-radius: 4px;
	font-family: painting, monospace, sans-serif;
	font-size: 5rem;
	cursor: pointer;
	outline: 0;
	transition-duration: .2s;
}
button:hover {
	background-color: #484c84;
	color: #878bc4;
	margin-top: 3.5rem;
	padding: 18px 50px;
	border-radius: 4px;
	font-family: painting, monospace, sans-serif;
	font-size: 5.5rem;
	cursor: pointer;
}
.palette {
	margin-top: 3rem;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	width: 90%;
}
.palette div {
	content: '';
	background-color: #484c84;
	height: 29rem;
	width: calc(100%/3);
	border: 2px solid #000;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	align-items: center;
}
.hexcode {
	cursor: text;
	font-size: 1.7rem;
	text-align: center;
}
.pickers {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;

}
.pickers>div {
	margin: 1rem 5rem;
}
